@import '../../../utils.scss';

* {
    transition: all 0.2s;
}



.imgAnimation {
    animation-delay: -2s;
    display: flex;
    flex-direction: column;
    
}

.first_ {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    
    .md_text {
        // height: 130px;
        text-align: center;
        margin-top : 174px;
        font-size: 90px;
        font-weight: 100;
        line-height: 92px;
        transition: all 0.2s;
    }

    .portfolio{
        display: flex;
        flex-direction: column;
        transition: all 0.2s;
        margin-bottom: 50px;
        text-align: center;
        cursor: pointer;
        font-family: "Montserrat-Regular";

        .contents {
            font-family: "Montserrat-Regular";;
            justify-content: center;
            font-size: 40px;
            font-weight : 300;
            display: inline;
            // padding: 0 50px 0 0;
            box-sizing: border-box;
            position: relative;
            z-index: 6;
            color: #ffffff;
            transition: all 0.2s;
            text-align: center;

            span {
                line-height: 50px;
                font-size: 60px;
                transition: all 0.2s;
                position: absolute;
                right: -50px;
                top: 0;
            }
        }
        .contentsname{
            margin-top : 20px;
            text-align: center;
        }

        &:hover {
            color: white;
            
            // .hoverContents {
            //     width: 100%;
            //     opacity: 1;
            //     color: white;
            //     span {
            //         opacity: 1;
            //     }
            // }
            

            .contents {
                // padding-bottom: 10px;
                // color: #ff1f29;
                text-align: center;
            }

            .contentsname{
                
                text-align: center;
            }
        }
        .MiddleText {
            font-family: Montserrat-light;
            text-align: center;
            color: #ff1f29;
            font-size: 40px;
            font-weight: 200;
            h1{
                font-size: 40px; 
            }
        
            &:hover{
                font-family: Montserrat-regular;
                color: #199dfb;
                // font-weight: 400;
                // font-size: 40px;
            }
        }

        .hoverContents {
            margin-top: 28px;
            opacity : 1;
            // span{
            //     font-weight: 600;
            //     line-height: 2.3;
            // }
            font-size: 18px;
            font-weight: 100;
            font-family: 'Montserrat-light,Noto Sans KR';
            line-height: 1.67;
        }
         
        

    }
    .bold_text0 {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        font-family: "Montserrat-light";
        font-size: 40px;
        font-weight: 300;
        max-height : 80px;
        h1{
            font-size: 40px;
        }
        
    }
    .bold_text {
        font-family: "Montserrat-Bold";
        // margin-top : 20px;
        text-align: center;
        font-size: 90px;
        font-weight: 100;
        line-height: 92px;
        transition: all 0.2s;
        padding-bottom: 38px;
        &:hover {
            color : #199dfb;
        }

    }

    .time_text {
        font-size: 26px;
        font-weight: 100;
        line-height: 90px;

        span{
            font-size: 16px;
            font-family: "Montserrat-Medium";
            
        }

        .time_num {
            font-size: 150px;
            font-weight: 100;
            font-family: Montserrat-Thin;
        }

    }
    
}
.bt_text_box{
    display: flex;
    justify-content: center;
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 200;
    margin-top: 84px;

    .bt_text_01{
        color: #ffffff;

    }
    .bt_text_02{
        color:#199dfb;
        padding-left: 5px;
        font-weight: 600;

    }

}

@media (max-width: 1024px) {

    .first_ {
        .bold_text{
            font-size: 80px;
        }

        .portfolio { 
            margin-bottom: 100px;
            .hoverContents{
                font-size: 17px;
            }
            .contents {
                left: initial;
                font-size: 27px;
                span {
                    font-size:35px ;
                    line-height: 50px;
                    font-size: 60px;
                    right: -90px;
                }
            }
        }

        .md_text {
            margin-top : 50px;
            font-size: 70px;
            line-height: 70px;
        }

        .time_text{
            line-height: 50px;

            span {
                 width: 300px ; 
                font-size: 30px;
                display: flex;
              }
              .time_num {
                  display: none;
              }
          }
    }
    .bt_text_box{
        letter-spacing: 0.5px;
        margin-top: 0px;
        font-size: 15px;
    }
    .MiddleText{
        font-size: 40px;
    }
   
   
}
@media (max-width:860px) {
    .first_{
        .bold_text{
            font-size: 70px;
        }
        .bold_text0{
            font-size: 35px;
            max-height: 50px;
            h1{
                font-size: 35px;
            }
        }
        .portfolio{
            .hoverContents{
                font-size: 15px;
            }
            
        }
        .MiddleText{
            font-size: 38px;
            h1{
                font-size: 38px;
            }
        }
    }
}

@media (max-width: 768px) {
    .first_ {

        .bold_text0{

        }
        .portfolio { 
            .contents {
                font-size: 30px;
            }
            .hoverContents{
                font-size: 14px;
                margin-top: 20px;
            //     br {
            //         display: none;
            //    }
            }
        }
        .MiddleText{
            font-size: 35px;
            h1{
                font-size: 35px;
            }
        }

        // .time_text{
        //     line-height: 60px;

        //     span {
        //           font-size: 30px;
        //           display: flex;
        //       }
        //       .time_num {
        //           display: none;
        //       }
        //   }
        //   .bt_text_box{
        //       margin-top: -10px;
        //       font-size: 14px;
        //   }
    }
}
@media (max-width: 730px) {
    .first_ {

        .bold_text0{

        }
        .portfolio { 
            .contents {
            }
            .hoverContents{
                br {
                    display: none;
               }
            }
        }
        .MiddleText{
        }
    }
}
@media (max-width: 712px) {
    .first_ {

        .bold_text0{

        }
        .portfolio { 
            flex-direction: column;
            align-items: center;
            .contents {
                font-size: 30px;
            }
            .MiddleText{
                font-size: 32px;
                h1{
                    font-size: 32px;
                }
            }
            
        &:hover {
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            .hoverContents {
                width: 80%;
                opacity: 1;
                color: white;
                span {
                    opacity: 1;
                }
            }
        }
            .hoverContents{
                width: 80%;
                font-size: 14px;
                margin-top: 20px;
                
            }
        }

        // .time_text{
        //     line-height: 60px;

        //     span {
        //           font-size: 30px;
        //           display: flex;
        //       }
        //       .time_num {
        //           display: none;
        //       }
        //   }
        //   .bt_text_box{
        //       margin-top: -10px;
        //       font-size: 14px;
        //   }
    }
}

@media (max-width: 568px) {
    .first_ {

        .bold_text0{
            max-height: 30px;
            font-size: 25px;
            h1{
                font-size: 25px;
            }

        }

        .bold_text{
            margin-top: 10px;
            font-size:50px ;
        }

        .portfolio { 
            .hoverContents{
                font-size: 13px;
                line-height: 1.67;
                >span {
                    line-height: 1.67;
                    font-size: 13px;
                   
                }
            }
            .contents {
                font-size: 20px;
                padding: initial;

            }
            .MiddleText{
                font-size: 30px;
                h1{
                    font-size: 30px;
                }
            }
        }
        .bt_text_box{
            margin-top: -30px;
        }

        .md_text {
            height: 20px;
        }
    }
}

@media (max-width: 400px) {
    .first_ {
        .bold_text0{
            font-size: 25px;
            h1{
                font-size: 25px;
            }
        }
        .bold_text{
            margin-top:10px;
            font-size:50px ;
        }

        .portfolio { 
            .hoverContents{
                font-size: 12px;
            }
            .contents {
                font-size: 20px;
                padding: initial;

            }
            .MiddleText{
                font-size: 28px;
                h1{
                    font-size: 28px;
                }
            }
        }
        .bt_text_box{
            margin-top: -30px;
        }

        .md_text {
            height: 20px;
        }

        // .time_text{
        //     font-size: 20px;
        //     line-height: 50px;

        //     span {
        //          width: 300px ; 
        //         font-size: 30px;
        //         display: flex;
        //     }
        //     .time_num {
        //         display: none;
        //     }
        // }
    }
}

@media (max-width: 320px) {
    .first_ {


        .bold_text0{
            font-size: 25px;

        }
        .bold_text{
            margin-top:10px;
            font-size:50px ;
        }

        .portfolio { 
            .hoverContents{
                font-size: 10px;
                margin-top: 0px;
            }
            .contents {
                font-size: 20px;
                padding: initial;

            }
            .MiddleText{
                font-size: 22px;
                h1{
                    font-size: 22px;
                }
            }
        }
        .bt_text_box{
            margin-top: -30px;
        }

        .md_text {
            height: 20px;
        }
    }
}

.hoverConentsRevmover{
    opacity: 0 !important;
}