.second_ {
    
    .brand_name_wr {
        // width: 100%;
        // height: 60px;
        // margin-bottom: 50px;
        position: absolute;
        top: 10%;
        left: 2%;
        font-size: 14px;
        line-height: 25px;
        .par {
            float: left;
            font-family: Montserrat-Medium;
            line-height: 24px;
            > * {
                font-family: Montserrat-Medium;
                font-size: 14px;
            }

            &.one {
                color: #ff1f29;
                width: 80%;
                font-weight: 600;
            }

            &.two {
                width: 80%;
                font-weight: 600;
            }
            &.three{
                width: 80%;
                color:#199dfb;
                font-weight: 600;
            }

            .wr_p {
                &.two {
                    font-family: Montserrat-Thin;
                    line-height: 20px;
                }
                
            }

        }

    }

    .text_wr {
        max-width: 1120px;
        max-width: 100%;
        font-size: 84.5px;
        font-weight: 100;
        text-align: center;
        line-height: 8.5rem;
        p {
            font-weight: 100;
            word-break: keep-all;
            // font-family: "Noto Sans KR", sans-serif;
            transition: all 0.2s;
        }
    }
    .top_text_box{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .top_text{
            font-family: 'Montserrat-bold';
            text-align: left;
            padding-right: 80px;
        }
        .top_border{
            max-width: 389px;
            width: 100%;
            height: 3px;
            background-color: #ff1f29;

        }
    }
    .middle_text_box{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .middle_text{
            font-family: 'Montserrat-bold';
            text-align: right;
            padding-left: 80px;

        }
        .middle_border{
            max-width: 389px;
            width: 100%;
            height: 3px;
            background-color: #ffffff;
            
        }
    }
    .bottom_text_box{
        display: flex;
        align-items: center;
        justify-content: center;
        .bottom_text{
            font-family: 'Montserrat-bold';
            text-align: center;
            padding-right: 50px;

        }
        .bottom_border{
            max-width: 389px;
            width: 100%;
            height: 3px;
            background-color: #199dfb;
        }
    }

}
// @media (max-width: 1540px) {
//     .second_ {
//         .text_wr {
//             font-size: 80px;
//             line-height: 7.5rem;
//         }

//        .brand_name_wr {
//            width: 700px;
//        }
//     }
// }
@media (max-width: 1340px) {
    .second_ {
        .text_wr {
            max-width: 600px;
            width: 100%;
            margin: auto;
            font-size: 50px;
            line-height: 5.5rem;
        }

       .brand_name_wr {
           width: 600px;
           font-size: 15px;
            .par {
                display: block;
            }
       }
       .top_text_box{
            .top_text{
                padding-right: 60px;
            }
            .top_border{
                max-width: 200px;
                width: 100%;

            }
        }
        .middle_text_box{
            .middle_text{
                padding-left: 60px;

            }
            .middle_border{
                max-width: 200px;
                width: 100%;
                
            }
        }
        .bottom_text_box{
            .bottom_text{
                padding-right: 40px;

            }
            .bottom_border{
                max-width: 200px;
                width: 100%;
            }
        }
    }
    
}

@media (max-width: 988px) {
    .second_ {
        .brand_name_wr{
            top: 10%;
            left: 0%;
            .par{
                font-size: 12px;
                &.one{
                    line-height: 2;
                    display: block;
                }
                &.two{
                    line-height: 2;
                }
                
                
            }
        }
        .text_wr {
            max-width: 500px;
            width: 100%;
            margin: auto;
            font-size: 45px;
            line-height: 4.5rem;
        }
        .top_text_box{
            .top_text{
                padding-right: 45px;
            }
            .top_border{
                max-width:150px;
                width: 100%;

            }
        }
        .middle_text_box{
            .middle_text{
                padding-left: 45px;

            }
            .middle_border{
                max-width:150px;
                width: 100%;
                
            }
        }
        .bottom_text_box{
            .bottom_text{
                padding-right: 40px;

            }
            .bottom_border{
                max-width:150px;
                width: 100%;
            }
        }
    }
}

@media (max-width: 830px) {
    .second_ {
        .brand_name_wr{
            top: 10%;
            left: 5%;
            .par{
                font-size: 12px;
                &.one{
                    line-height: 2;
                }
                &.two{
                    line-height: 2;

                }
            }
        }
        .text_wr {
            max-width: 400px;
            width: 100%;
            margin: auto;
            font-size: 35px;
            line-height: 3.5rem;
        }
        .top_text_box{
            .top_text{
                padding-right: 40px;
            }
            .top_border{
                max-width:135px;
                width: 100%;
                height: 2px;

            }
        }
        .middle_text_box{
            .middle_text{
                padding-left: 40px;
                
            }
            .middle_border{
                max-width:135px;
                width: 100%;
                height: 2px;
                
            }
        }
        .bottom_text_box{
            .bottom_text{
                padding-right: 30px;

            }
            .bottom_border{
                max-width:135px;
                width: 100%;
                height: 2px;
            }
        }
    }
}

@media (max-width: 687px) {
    .second_ {
            .brand_name_wr{
                top: 10%;
                left: 5%;
                .par{
                    font-size: 11px;
                }
            }
        .text_wr {
            font-size: 30px;
        }
        .top_text_box{
            .top_text{
                padding-right: 30px;
            }
            .top_border{
                max-width:130px;
                width: 100%;

            }
        }
        .middle_text_box{
            .middle_text{
                padding-left: 30px;
                
            }
            .middle_border{
                max-width:130px;
                width: 100%;
                
            }
        }
        .bottom_text_box{
            .bottom_text{
                padding-right: 23px;

            }
            .bottom_border{
                max-width:130px;
                width: 100%;
            }
        }
    }
}


@media (max-width: 484px) {
    .second_ {
        .brand_name_wr{
            top: 10%;
                left: 5%;
            .par{
                &.one{
                    line-height: 1.5;
                }
                &.two{
                    line-height: 1.5;

                }
            }
        }
        .text_wr {
            max-width: 200px;
            font-size: 19px;
        }
        .top_text_box{
            .top_text{
                padding-right: 18px;
            }
            .top_border{
                max-width:60px;
                width: 100%;

            }
        }
        .middle_text_box{
            .middle_text{
                padding-left: 18px;
                
            }
            .middle_border{
                max-width:60px;
                width: 100%;
                
            }
        }
        .bottom_text_box{
            .bottom_text{
                padding-right: 13px;

            }
            .bottom_border{
                max-width:60px;
                width: 100%;
            }
        }
    }
}