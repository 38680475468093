@import '../../../utils.scss';

.success_popup_wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;

    .success_popup {
        width: 340px;
        height: 350px;
        box-shadow: 0 0px 1000vw black;
        position: relative;
        background-color: white;

        .header {
            width: 100%;
            height: 50%;
            background-color: $mainColor;
            opacity: 0.9;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                text-align: center;
                span {
                    display: block;
                    font-weight: bold;
                    font-size: 100px;
                    color: $mainColor;
                    background-color: white;
                    border-radius: 100%;
                    // box-sizing: border-box;
                    padding: 10%;

                    &:hover {
                        animation-name: swing;
                        animation-iteration-count: infinite;
                    }
                }
            }
        }
        
        .close_button {
            width: 30px;
        }

        .contents_wrapper {
            width: 100%;
            height: 50%;
            text-align: center;

            p {
                width: 100%;
                color: black;
            }

            .title {
                font-size: 30px;
                font-weight: bold;
                line-height: 60px;
            }
        }

        .close_button {
            width: 90%;
            height: 40px;
            background-color: $mainColor;
            text-align: center;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            margin: 0 auto;
            border-radius: 10px;
            transition: all 0.2s;
            &:hover {
                opacity: 0.9;
                cursor: pointer;
            }
        }
    }
}

@-webkit-keyframes swing {
    20% {
      -webkit-transform: rotate3d(0, 0, 1, 15deg);
      transform: rotate3d(0, 0, 1, 15deg);
    }
  
    40% {
      -webkit-transform: rotate3d(0, 0, 1, -10deg);
      transform: rotate3d(0, 0, 1, -10deg);
    }
  
    60% {
      -webkit-transform: rotate3d(0, 0, 1, 5deg);
      transform: rotate3d(0, 0, 1, 5deg);
    }
  
    80% {
      -webkit-transform: rotate3d(0, 0, 1, -5deg);
      transform: rotate3d(0, 0, 1, -5deg);
    }
  
    to {
      -webkit-transform: rotate3d(0, 0, 1, 0deg);
      transform: rotate3d(0, 0, 1, 0deg);
    }
  }
  
  @keyframes swing {
    20% {
      -webkit-transform: rotate3d(0, 0, 1, 15deg);
      transform: rotate3d(0, 0, 1, 15deg);
    }
  
    40% {
      -webkit-transform: rotate3d(0, 0, 1, -10deg);
      transform: rotate3d(0, 0, 1, -10deg);
    }
  
    60% {
      -webkit-transform: rotate3d(0, 0, 1, 5deg);
      transform: rotate3d(0, 0, 1, 5deg);
    }
  
    80% {
      -webkit-transform: rotate3d(0, 0, 1, -5deg);
      transform: rotate3d(0, 0, 1, -5deg);
    }
  
    to {
      -webkit-transform: rotate3d(0, 0, 1, 0deg);
      transform: rotate3d(0, 0, 1, 0deg);
    }
  }
  
  .swing {
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing;
  }
  