body {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", 
     monospace;
}

@font-face {
  font-family: "Montserrat-Black";
  font-style: normal;
  font-weight: 900;
  src: url("./_assets/fonts/Montserrat-Black.ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./_assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat-Light";
  font-style: normal;
  font-weight: 200;
  src: url("./_assets/fonts/Montserrat-Light.ttf");
}

@font-face {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 400;
  src: url("./_assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 300;
  src: url("./_assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-Thin";
  font-style: normal;
  font-weight: 100;
  src: url("./_assets/fonts/Montserrat-Thin.ttf");
}

@font-face {
  font-family: "Montserrat-ExtraLight";
  font-style: normal;
  font-weight: 100;
  src: url("./_assets/fonts/Montserrat-ExtraLight.ttf");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  font-style: bold;
  font-weight: 600;
  src: url("./_assets/fonts/Montserrat-SemiBold.ttf");
}


/* noto-sans-kr-100 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: local('Noto Sans KR Thin'), local('NotoSansKR-Thin'),
       url('./_assets/fonts/noto-sans-kr-v12-korean-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./_assets/fonts/noto-sans-kr-v12-korean-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* noto-sans-kr-300 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: local('Noto Sans KR Light'), local('NotoSansKR-Light'),
       url('./_assets/fonts/noto-sans-kr-v12-korean-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./_assets/fonts/noto-sans-kr-v12-korean-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* noto-sans-kr-regular - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans KR Regular'), local('NotoSansKR-Regular'),
       url('./_assets/fonts/noto-sans-kr-v12-korean-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./_assets/fonts/noto-sans-kr-v12-korean-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* noto-sans-kr-500 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: local('Noto Sans KR Medium'), local('NotoSansKR-Medium'),
       url('./_assets/fonts/noto-sans-kr-v12-korean-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./_assets/fonts/noto-sans-kr-v12-korean-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* noto-sans-kr-700 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Sans KR Bold'), local('NotoSansKR-Bold'),
       url('./_assets/fonts/noto-sans-kr-v12-korean-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./_assets/fonts/noto-sans-kr-v12-korean-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* noto-sans-kr-900 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: local('Noto Sans KR Black'), local('NotoSansKR-Black'),
       url('./_assets/fonts/noto-sans-kr-v12-korean-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./_assets/fonts/noto-sans-kr-v12-korean-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}



*, body {
  font-family: "Montserrat-ExtraLight", "Noto Sans KR";
  font-weight: 100;
}
