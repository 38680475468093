.contact {
    width: 100%;
    height: 100%;
    // overflow-y: auto;
    position: relative;
    color: black;
    // top: 200px;
    animation-delay: 0.5s;
    
    .scroll {
        width: 100%;
        height: 81%;
    }

    .contact_us {
        font-family: 'Montserrat';
        margin-bottom: 10px;
        font-size: 40px;
        font-weight: bold;
        color: black;
        position: relative;
        text-align: center;
        padding: 60px 0;
        .contents{
            position: relative;
            width: 40%;
            height: 40%;
            text-align: left;
        }
    }

    .span_wrap {
        height: 40px;
        margin-top: 30px;
        position: relative;
        box-sizing: border-box;
        &::before {
            content: "";
            
            width: 700px;
            left: 0;
            box-sizing: border-box;
            float: left;
            color: black;
            top: 10px;
            position: absolute;
            padding-left: 20px;
        }

    }

    // .titleLine { 
    //     margin-left: 5px;
    //     width: 500px;
    //     border-top: 3px solid;
    //     margin-bottom: 30px;
    // }

    .textDesc {
        display: flex;
        height: 100%;
        box-sizing: border-box;
    }

    .explanation {
        width: 100%;
        height: 100%;
        font-weight: bolder;
        font-size: 10px;
        background: #1d1d1d;
        // padding: 6.8%;
        // margin-top : 300px;
        color: #ffffff;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .top{
            display: flex;
            flex-direction: column;
            padding: 12.8%;
            font-size: 16px;
            font-weight: bold;
            flex: 1;
        }
        .bottom{
            display: flex;
            flex-direction: column;
            padding: 12.8%;
            flex: 1;
           justify-content: flex-end;
            .title{
                font-size: 12px !important;
                text-align: right;
            }
            .text{
                font-size: 14px;
                font-weight: bold;
                text-align: right;
            }
            .text1{
                font-size: 12px;
                font-weight: 100;
                text-align: right;
            }
        }
    }
    .firstText {
        font-weight: bolder;
        color: #ffffff;
    }
    .imgMap {
        width: 100%;
        height: 100%;
        iframe { 
            width: 100%;
            border: 0;
            height: 100%;
            
        }
    }
}

@media(min-height: 1366px) { 

    .contact { 
        
        .imgMap { 
            iframe { 
                width: 900px;

            }
        }

    }
}

@media(max-width: 1660px) { 


    .contact { 
        
      
        .imgMap { 

            iframe { 
                width: 100%;

            }
        }

        .explanation {
            // position: absolute;
            font-weight: bolder;
            font-size: 10px;
            // margin-top : 400px;
            color: #ffffff;
        }

        .titleLine { 
            width: 700px;
        }
    
    }
    
    .imgMap {
        width: 100%;
    }

    .textDesc {
        // position: absolute;
    }

}

    @media(max-width: 1311px) { 

    .contact { 
        .explanation {
            // margin-top : 400px;
            .top{
                padding-top: 18.8%;
                padding-left: 18.8%;
            }
            .bottom{
                padding-bottom: 18.8%;
                padding-right: 18.8%;
            }
        }

        .titleLine{
            width: 450px;
        }
       
    }
    
}


@media(max-width: 1111px) { 

    .contact { 
        .explanation {
            // margin-top : 400px
        }
    }
 
}

@media(max-width: 930px) { 

    .contact { 
        overflow: auto;
        .explanation {
            width: unset;
            height: unset;
        }
    }
    .textDesc {
        display: flex;
        flex-direction: column;
    }
}



@media(max-width: 823px) { 

    .contact { 
        height: 80%;
        top: 50px;
        .explanation {
            // margin-top : 400px;
        }
    }
}


@media(max-width: 812px) { 

    .contact { 
        .explanation {
            // margin-top : 400px;
        }
    }
}

@media(max-width: 768px) { 

    .contact { 
        height: 80%;
        .explanation {
            // margin-top : 400px;
        }
    
    }
}


@media(max-width: 484px) { 

    .contact { 
        .explanation {
            // margin-top : 400px;
        }
    }
}


@media(max-width: 414px) { 

    .contact { 
        .explanation {
            // margin-top : 400px;
        }

        .titleLine { 
            width: 250px;
        }
    }
}


@media(max-width: 330px) { 

    .contact { 
        .explanation {
            // margin-top : 400px;
        }

        .titleLine { 
            width: 230px;
        }

    }
}
