@import "~slick-carousel/slick/slick.css";
@import "../../../utils.scss";
//@import "~slick-carousel/slick/slick-theme.css";

/*slick-theme.scss*/

/* Slider */
.fp-tableCell{
  background-image: url(../../../common/function/constants/home/images/mainimg-05.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}
// .slick-prev:before
// {
//     content: '←';
// }
// [dir='rtl'] .slick-prev:before
// {
//     content: '→';
// }

.slick-next {
  right: -25px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}
// .slick-next:before
// {
//     content: '→';
// }
// [dir='rtl'] .slick-next:before
// {
//     content: '←';
// }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  top: -10px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;
  float: left;
  width: 10%;
  height: 4px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20%;
  height: 4px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;

  content: "";
  text-align: center;

  opacity: 0.25;
  background: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  top: -3px;
  color: black;
  height: 4px;
}

.slick-arrow {
  &.slick-prev {
    top: 400px;
    padding: 0px 0px 0px 100px;
    left: -60px;
    &::before {
      color: $mainColor;
      font-size: 70px;
    }
  }
  &.slick-next {
    right: -40px;
    top: 400px;
    padding: 0px 130px 0px 0px;
    &::before {
      color: $mainColor;
      font-size: 70px;
    }
  }
}

.fourth_ {
  color: #000;
  h1 {
    display: none;
    font-size: 80px;
    font-family: "Montserrat-Bold", "Noto Sans KR";
    margin-bottom: 40px;
  }

  .SliderInner {
    height: 340px;
    position: relative;

    .Title_img {
      position: absolute;
      top: -70px;

      img {
        width: 40%;
        margin: 20px 0px;
      }
    }

    .Year {
      width: 90%;
      margin-right: 5%;
      margin-top: 15px;

      h2 {
        font-size: 12px;
        font-weight: 600;
        font-family: "Montserrat-Medium";
        margin-bottom: 3px;
        color: #222222;
        
      }
    }
  }
}

.years {
  padding: 15px 0px;
  margin-bottom: 30px;
  p {
    color: #222222;
    font-size: 24px;
    font-weight: bold;
    font-family: "Montserrat-Bold";
  }
}
.slick-list{
  overflow:initial;
}


@media (max-width: 1023px) {
  .fourth_ {
    h1 {
      font-size: 30px;
    }

    .years {
      padding: 15px 0px 0px 0px;
    }

    .slick-arrow {
      &.slick-prev {
        &::before {
          font-size: 50px;
        }
      }
      &.slick-next {
        &::before {
          font-size: 50px;
        }
      }
    }
  }
}

@media (max-width: 823px) {
  h1 {
    font-size: 40px;
    margin-top: 20px;
  }
  .fourth_ {
    height: 10%;
    .slick-arrow {
      &.slick-prev {
        &::before {
          font-size: 50px;
        }
      }
      &.slick-next {
        &::before {
          font-size: 50px;
        }
      }
    }
  }
}

@media (max-width: 731px) {
  h1 {
    font-size: 40px;
    margin-top: 10px;
  }
  .fourth_ {
    height: 10%;
    .slick-arrow {
      &.slick-prev {
        &::before {
          font-size: 50px;
        }
      }
      &.slick-next {
        &::before {
          font-size: 50px;
        }
      }
    }
  }
}

@media (max-width: 568px) {
  h1 {
    font-size: 35px;
  }
  .fourth_ {
    height: 10%;
    .slick-arrow {
      &.slick-prev {
        &::before {
          font-size: 50px;
        }
      }
      &.slick-next {
        &::before {
          font-size: 50px;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .fourth_ {
    height: 400px;
    .slick-arrow {
      &.slick-prev {
        &::before {
          top: 600px;
          font-size: 50px;
        }
      }
      &.slick-next {
        &::before {
          top: 600px;
          font-size: 50px;
        }
      }
    }
  }
}
