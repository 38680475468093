@import '../../../utils.scss';

.fifth_{

    .logoimg{
        position: absolute;
        right: 3%;
        top : 46.4%;
    }

    .one{
        position: absolute;
        top: 10%;
        left: 2%;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1.2px;
        font-family: "Montserrat-Medium";

        
        p{
            font-weight: 600;
            font-family: Montserrat;
            color: #ff1f29
        }
        span{
            color:#199dfb;
            font-family: "Montserrat-Medium";
        }
    }

    .two{
        position: absolute;
        top: 10%;
        left: 25%;
        font-size: 14px;
        line-height: 25px;

        h3{
            font-family: "Montserrat-Thin";
            span{
                font-family: "Montserrat-Medium";
            }
        }
        
    }

    .three{
        position: absolute;
        left: 0px;
        // top: 10%;
        width: 280px;

        // .three_inner_wrapper {
        //     width: 280px;
        //     position: relative;
        //     right: 0;
        // }
        .threeInner{
            margin-bottom: 50px;
            h3{

                font-size: 34px;
                    font-family: "Montserrat-Bold";
                margin-left: 5px;
            }
            .lernMoreArea{
                position: relative;
                width: 80%;
                margin: -6px 0;
                padding: 0 5%;
                margin-top: 12px;
                height: 35px;
                line-height: 35px;
                cursor: pointer;
                font-family: "Montserrat-Regular";
                .moveicon{
                    position: absolute;
                    right: 8px;
                    top: 2px;
                    width: 15px;
                    height: 15px;
                    opacity: 0;
                    // -webkit-transition: 0.5s;
                    // -o-transition: 0.5s;
                    // transition: 0.5s;
                    
                }
                .learnText{
                    letter-spacing: -0.6px;
                    font-size: 14px;
                    font-family: "Montserrat-Regular";
                }
                &:hover {
                    color: #000000;
                    background-color: #199dfb;
                    .moveicon{
                        opacity: 1;
                    }
                }

                a {
                    -webkit-transition: all 0.2s;
                    -o-transition: all 0.2s;
                    transition: all 0.2s;
                    color: white;
                    &:hover {
                    color: #000000;
                    background-color: #199dfb;
                    .moveicon{
                        opacity: 1;
                    }
                    }
                    }
                    &:active {
                        color: $mainColor;
                    }
                }
            }
            .emailArea{
                position: relative;
                width: 80%;
                margin: -6px 0;
                padding: 0 5%;
                margin-top: 12px;
                height: 35px;
                line-height: 35px;
                transition : none;
                cursor: pointer;

                .mailicon{
                    position: absolute;
                    right: 8px;
                    top: 2px;
                    width: 15px;
                    height: 15px;
                    opacity: 0;
                    // -webkit-transition: 0.5s;
                    // -o-transition: 0.5s;
                    // transition: 0.5s;
                    
                }

                &:hover{
                    color: #000000;
                    background-color: #ff1f29;
                    .mailicon{
                        opacity: 1;
                    }
                }
                p{
                    font-size: 15px;
                    font-family: "Montserrat-Regular";
                
                }
            }
            .emailAreaTwo{
                position: relative;
                width: 80%;
                margin: -6px 0;
                padding: 0 5%;
                margin-top: 12px;
                height: 35px;
                line-height: 35px;
                cursor: pointer;

                .mailicon{
                    position: absolute;
                    right: 8px;
                    top: 2px;
                    width: 15px;
                    height: 15px;
                    opacity: 0;
                    // -webkit-transition: 0.5s;
                    // -o-transition: 0.5s;
                    // transition: 0.5s;
                    
                }

                &:hover{
                    color: #000000;
                    background-color: #ffffff;
                    .mailicon{
                        opacity: 1;
                    }
                }
                p{
                    font-size: 14px;
                    font-family: "Montserrat-Regular";
                
                }
            }

            
        }
        .lennon_logo{
            position: absolute;
            top: -35px;
            right: calc(100% - 1250px);
            
            
        }
    }   


    @media (max-width: 1024px) {
        

        .logoimg {
            display: none;
        }
        .one { 
            display: none;
        }

        .three {
            width: 80%;
            position: relative;

            .threeInner {
                h3 { 
                    font-size: 20px;
                    line-height: 15px;
                }
                .lernMoreArea { 
                    font-size: 15px;
                    line-height: 25px;
                }
            }
        }

    }

    @media (max-width: 476px) { 
        .first_ { 
            
            .md_text { 
                font-size: 30px;
            }
        }
    }


.email_popup_black {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #00000088;
    z-index: 5;
}


.email_popup {
    width: 700px;
    height: 80vh;
    position: absolute;
    left:calc(50% - 350px);
    z-index: 9;
    top: calc(50% - 40vh);
    background-color: white;

    .orange_area {
        width: 100%;
        // padding: 10% 10%;
        padding: 7% 5% 10%;
        box-sizing: border-box;
        background-color: #353535;
        height: 60%;
    }

    .white_area {
        width: 100%;
        padding: 7% 5% 10%;
        background-color: #353535;
        box-sizing: border-box;
        height: 40%;
        position: relative;
    }

    .info_use {
        display: flex;
        align-items: center;
        color: white;
        font-size: 13px;
    }

    .warning_text {
        font-size: 14px;
        color: $mainColor;
        bottom: 30%;
        text-align: center;
        margin-bottom: 0;
        font-weight: bold;
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        left: 0;
        padding: 0 10px;
    }

    .info_use_box {
        width: 100%;
        height: 50%;
        border: 1px solid $mainColor;
        overflow-y: auto;
        overflow-x: hidden;
        @include scroll-style;
        position: relative;
        box-sizing: border-box;
        margin-top: -5px;
        background-color: white;

        .scroll {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            word-break: break-all;
            color: $mainColor;
            font-size: 13px;
            line-height: 30px;
            padding: 20px;
        }
    }

    .email_popup_inner {
        width: 100%;
        height: 100%;
        position: relative;
        box-sizing: border-box;
        z-index: 10;
        // overflow-x: hidden;
        // overflow-y: scroll;
        > * {
            word-break: break-all;
        }
    }

    .email_popup_inner_scroll {
        width: 100%;
        position: relative;
        height: 100%;

        .scroll {
            height: 100%;
        }
    }

    .popup_contents_inquiry {
        width: 100%;
        height: 50%;
        margin-top: 40px;
    }

    .connect_with_us {
        font-weight: bold;
        font-size: 40px;
        font-family: "Montserrat";
    }

    .famoz_contact {
        margin-left: 5px;
        margin-top: 5px;
    }

    .inquiry_contents {
        margin-top: 5%;
        padding-left: 5px;
        box-sizing: border-box;
    }
    
    textarea {
        width: 100%;
        height: 90%;
        padding: 15px;
        box-sizing: border-box;
        overflow-y: auto;
        resize: none;
        margin-top: 3%;
        @include scroll-style;
    }
    
    .send {
        height: 15%;
        border: 1px solid white;
        margin-top: 10%;
        margin-left: auto;
        margin-right: auto;
        width: 200px;
        font-family: "Montserrat";
        font-weight: bold;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s;
        position: absolute;
        bottom: 10%;
        left: 0;
        right: 0;


        &:hover {
            background-color: $mainColor;
            color: white;
            cursor: pointer;
        }

        &:active {
            transform: translateY(2px);
        }

        &.disabled {
            color: #8e8e8e;
            border: 1px solid #8e8e8e;
            cursor: default;

            &:hover {
                background-color: transparent;
                color: #8e8e8e;
                cursor: default;
            }

            &:active {
                transform: translateY(0);
            }
        }

    }

    .popup_contents_input {
        width: 100%;
        height: 30%;
        margin-top: 5%;

        .Dropdown-root {
            width: 80%;
        }
        .Dropdown-control {
            margin-left: 7px;
            width: 100%;
            font-size: 13px;
            color: white;
            background-color: inherit;
            border: none;
            border-bottom: 1px solid white;

            span {
                border-color: white transparent transparent;
            }

        }

        .Dropdown-menu {
            width: 100%;
            overflow-y: auto;
            @include scroll-style;

            .Dropdown-option {
                background-color: #eeeeee;
                color: $mainColor;

                &.is-selected {
                    background-color: #d8d8d8;
                    font-family: "Montserrat";
                    font-weight: bold;
                }
            }
        }

        .half {
            width: 45%;
            margin-bottom: 3%;
            min-height: 30px;
            height: 20%;
            display: flex;
            align-items: center;
            float: left;

            label {
                width: 30%;
                overflow: hidden;
                font-size: 14px;
                box-sizing: border-box;
            }

            &:nth-child(even) {
                margin-left: 8%;
            }
        }

        input {
            width: 70%;
            height: 100%;
            padding-left: 10px;
            box-sizing: border-box;
            color: white;
            background-color: inherit;
            border: none;
            box-shadow: none;
            border-bottom: 1px solid white;

            &::placeholder {
                color: white;
            }
        }
    }
    
    .X_btn {
        border-radius: 100%;
        display: flex;
        align-items: center;
        width: 50px;
        height: 50px;
        text-align: center;
        position: absolute;
        text-align: right;
        transition: all 0.2s;
        background-color: transparent;
        color: white;
        right: 15px;
        top: 15px;
        z-index: 20;

        &:hover {
            cursor: pointer;
            color: white;
            transform: translateY(5px)
        }

        &.inner {
            display: none;
        }

        &:active {
            // transform: scale(1) translateY(5px);
            color: $subColor;
        }
        span {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 35px;
            font-weight: thin;
        }
    }
}

@media (max-height: 600px) {
    .email_popup_inner_scroll {
        height: 60vh !important;
        top: calc(50% - 30vh);
    }
}

@media (max-width: 1024px) {
    .email_popup {
        // position: fixed;
        position: relative;
        bottom: 0;
        z-index: 30;
        width: 90vw;
        left: -15%;
        height: 100vh;
        top: 0;
        
        .orange_area {
            // padding-top: 20px;

            .connect_with_us {
                font-size: 25px;
            }
        }

        .X_btn {
            left:10px;
            top: 0;
            background-color: transparent;
            span {
                font-size: 20px !important;
                color: white;
            }

            &.out {
                display: none;
            }

            &.inner {
                display: flex;
            }
        }

        .email_popup_inner {
            height: 100%;
        }

        .email_popup_inner_scroll {
            height: 100% !important;

            .warning_text {
                font-size: 13px;
            }
        }
    }
}

@media (max-width: 650px) {
    .warning_text {
        font-size: 11px !important;
    }
    .email_popup{
        .orange_area{
            padding-top: 10%;
        }
        .popup_contents_input {
            height: 50%;
            display: flex;
            flex-direction: column;
            input {
                width: 77%;                
                font-weight: 100;
            }
            .half:nth-child(even){
                width: 55%;
                margin-left: 0px;
            }
            .half{
                width: 55%;
                label{
                    font-size: 12px;
                    width: 80%;
                }
            }
            .Dropdown-control{
                margin-left: initial;
                padding-right: 30px;
            }
            .Dropdown-placeholder {
                font-size: 13px !important;
                font-weight: bold;
                white-space: nowrap;
            }
        }
    }
}
@media (max-height: 700px) {
    .email_popup_inner_scroll {
        height: 80vh !important;
        top: calc(50% - 50vh);
    }
    .email_popup textarea{
        margin-top: 7%;
        height: 70%;
    }
}
@media (max-height: 600px) {
    .email_popup_inner_scroll {
        height: 80vh !important;
        top: calc(50% - 50vh);
    }
    .email_popup textarea{
        margin-top: 9%;
    }
}

@media (max-width: 450px){
    .email_popup{
        left: -2% !important;
        .popup_contents_inquiry{
            margin-top: 30px;
        }

        .orange_area{
            padding-top: 18%;
            .connect_with_us{
                font-size: 20px;
            }
        }


        .popup_contents_input {
            .half:nth-child(even){
                width: 70%;
            }
    
            .half{
                width: 70%;
                label{
                }
            }
        }
    }
}




@media (max-height: 660px) {
    .popup_contents_inquiry {
        height: 42% !important;
    }

    .info_use {
        margin-top: -5%;
       
    }

    .warning_text {
        bottom: 29% !important;
    }
    .email_popup textarea{
        margin-top: 12%;
        height: 60%;
    }
    .email_popup{
        .white_area{
            padding-top: 10%;
        }
    }
}


@media (max-height: 510px) {
    .email_popup {
        height: 510px !important;
        top: calc(50% - 260px) !important;
    }
}