@import '../../../utils.scss';
@font-face {
    font-family:Gotham Rounded Bold;
     src:url('../../../_assets/fonts/Gotham Rounded Bold.otf'); 
     src:local(※), url("../../../_assets/fonts/Gotham Rounded Bold.otf") format(otf) 
}

.footer{
    font-size: 14px;
    font-family: "Gotham Rounded Bold" ,Gotham Rounded Medium;
    font-weight: 100;
    
    a{
        cursor: pointer;
        
    }
    p{  
        font-family: inherit;
        color: $subColor;
        cursor: pointer;
        &:hover{
            color: $mainColor;
            cursor: pointer;
        }
    }
}