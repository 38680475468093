@import '../../../utils.scss';

.popup_wrapper_all_genuin {
    margin: auto;
    background-color: #ffffff;
    z-index: 12;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 30px 30px 30px 30px;
    // border-radius: 10px;
    width: 90% !important;
    max-width: 1536px;
    height: initial !important;

    .header {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        position: relative;
        
        .X_btn {
            display: flex;
            align-items: center;
            width: 40px;
            height: 40px;
            top: -31px;
            right: -70px;
            text-align: center;
            position: absolute;
            text-align: right;
            transition: all 0.2s;
            background-color: #000000;
            box-shadow: none;
            color: #fff;

            &:hover {
                cursor: pointer;
                color: #fff;
                // transform: translateY(5px)
                span{
                    transform: rotate(180deg);
                }
            }

            &:active {
                // transform: scale(1) translateY(5px);
                color: $subColor;
            }
            span {
                display: block;
                width: 100%;
                text-align: center;
                font-size: 32px;
                font-weight: 100;
            }
        }
    }
        
    .popup_contetns {
        // display: none;
        min-width : 592px;
        width: 100%;
        height: calc(100%);
        margin-top: 0px;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative; 
        @include scroll-style;
        box-sizing: border-box;     

        .scroll_inner {
            
            position: absolute;
            width: 100%;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: auto;        
            box-sizing: border-box; 
            word-break: break-all  ;
            .img{
                width : 100%;
            }
            
        }

    }
}
@media (max-width: 1024px) {
    .popup_wrapper_all_genuin{
        height : 60% !important;
    }
}

@media (max-width: 768px) {
    .popup_wrapper_all_genuin{
        height : 70% !important;
    }
}



@media (max-width: 420px) {
    .popup_wrapper_all_genuin{
        height : 60% !important;
    }
}

@media (max-width: 375px) {
    .popup_wrapper_all_genuin{
        height : 66% !important;
    }
}

@media (max-width: 360px) {
    .popup_wrapper_all_genuin{
        height : 60% !important;
    }
}



@media (min-width: 701px) {
    .popup_wrapper_all_genuin {
        width: 40% ;
        

        .X_btn {
            background-color: #000000;
            top: -50px;
            right: -50px;
            -webkit-box-shadow: 0px 0px 5px 0px rgba(138,138,138,1);
            -moz-box-shadow: 0px 0px 5px 0px rgba(138,138,138,1);
            box-shadow: 0px 0px 5px 0px rgba(138,138,138,1);
        }
    }
} 

@media (max-width: 1600px) {
    
    
    .popup_contetns {
        .description {
            font-size: 1rem;
            line-height: 2rem;
        }
    }

    .title_wrapper {
        font-size: 1rem;
    }

    .title_left, .title_right {
        font-size: 1rem;
    }

    .title {
        font-size: 1.5rem !important;
    }
}


@media (max-width: 520px) {

    .popup_contetns {
        .description {
            font-size: 0.8rem;
            line-height: 1.3rem !important; 
        }
    }

    .title_wrapper {
        font-size: 0.8rem;
    }

    .title_left, .title_right {
        font-size: 0.7rem;
    }

    .title {
        font-size: 1.3rem !important;
    }
    
}

@media (max-width: 454px) {
    .popup_wrapper_all_genuin {
        .X_btn {
            z-index: 22;
            right: -30px !important;
            top: -40px !important;
            background-color:transparent !important;
            background-color: #000000;
            span {
                font-size: 20px !important;
                color: #000000;
            }
        }
    }
}

@media (max-width: 379px) {
    .popup_wrapper_all_genuin {
        .X_btn {
            z-index: 22;
            right: -25px !important;
            top: -35px !important;
            background-color:transparent !important;
            background-color: #000000;
            span {
                font-size: 20px !important;
                color: #000000;
            }
        }
    }
}
