.mainImage1 {
    // width: 302px;
    // height: 407px;

    img {
        width: 100%;
        height: auto;
    }
}
.plyr--full-ui input[type=range]{
    color :  #ffffff !important;
}
.plyr__control--overlaid{
    background-color: #000 !important;
}
.plyr__controls{
        // display: none;
        &:hover {
            cursor: pointer;
            color: #fff;
            // transform: translateY(5px)
            .plyr__control{
                background-color: unset !important;
                
            }
        }
    }
    

.overViewGenuin {
    width: 100%;
    color: #000000;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .left-title{
        flex: 3;
    }
    .right-title{
        flex: 5;
        display: flex;
        align-items: flex-end;
    }
    .overViewWrap{
        width: 100%;
        // margin-top: 40px;
    }
    .title {
        white-space: nowrap;
        font-size: 2.5rem;
    }
    .border {
        width: 100%;
        // margin-left: 20px;
        border: none;
        height: 100%;
        border-bottom: 1px solid #000000;
        margin-bottom: 0;
    }
    .type{
        margin-left : 7px;
        font-size: 20px;
        font-weight: 700;
        font-family: 'Montserrat,Noto Sans KR';
        margin-top : 30px;
        margin-bottom: 20px;
    }
    .firstline1{
        display : flex;
        align-items: center;
        margin-bottom : 40px;
    }
    .company{
        margin-top : 9px;
        font-weight: 600;
        font-size: 14px;
        font-family : 'Montserrat-SemiBold,Noto Sans KR';
        
    }
    .name{
        margin-left : 5px;
        font-family: 'Montserrat-Bold,Noto Sans KR';
        margin-right : 60px;
        font-size: 30px;
    }
    .experience{
        font-family: Noto Sans KR;
        font-size: 15px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.53;
        margin-bottom: 48px;;
    }
    .extext{
        font-family: Noto Sans KR;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.83;
        width: 251px;
        height: auto;
        >span{
            font-family: Montserrat;
            font-weight: 600;
            line-height: 2.23;
        }
    }
    .subImgsBox{
        display : flex;
        justify-content: space-between;
        &:nth-child(1) {
            margin-right : 10px;
        }
        
        .subImgsContainer{
            flex : 1;
            margin-right :10px;
            img{
                width : 100%;
            }
        }
        .subImgsContainer1{
            flex : 1;
            margin-left :10px;
            img{
                width : 100%;
            }
            
        }
        
        .subdectitle{
            margin-top : 30px;
            margin-bottom : 18px;
            font-size: 25px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.92;
            letter-spacing: normal;
            text-align: left;
            color: #232323;
        }
        .subdec{
            font-size: 15px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.53;
        }

    }
    .smallThumbs{
        margin-top : 112px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        img{
            width : 100%;
        }
        .smallThumbImg{
            width : 32.1%;
            margin-bottom : 20px;
        }
        
    }
    .behindTheSceneBox{
        text-align: center;
        font-family: Montserrat-bold;
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.77;
        letter-spacing: normal;
        color: #151515;
        margin-top : 90px;
        .behindTheSceneImgBox{
            margin-top : 53px;
            margin-bottom: 131px;;
            display: flex;
            justify-content: space-between;
            img{
                width : 100%;
            }
            .behindTheSceneImg{
                width : 49.1%;
            }
        }
    }
    .creditSBox{
        padding-top : 55px;
        padding-bottom : 55px;
        background-color: #efefef;
        text-align: center;
        font-family: Montserrat-bold;
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.77;
        letter-spacing: normal;
        color: #151515;
        
        .creditWrapper{
            margin-top : 50px;
            display: flex;
            text-align: left;
            justify-content: center;
            align-items: center;
            .jobTitle{
                margin-right : 39px;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                text-align: left;
                color: #151515;
            }
            .creditNames{
                text-align: right ;
                font-family: Montserrat-Medium;
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                color: #151515;
            }

        }

    }
    .footer1{
        background-color:  #151515;
        display: flex;
        justify-content: center;
        .footerlogo{
            margin-top : 83px;
            margin-bottom: 78px;;
        }
    }
}

.mainVideo1 {
    width: 100%;
    position:relative;
    height : 85.8vh;
    .iframe{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
        video {
            width: 100%;
            height: auto;

        }
}

.description {
    width: 100%;
    margin: 40px auto;
    color: #000000;
    font-size: 1.5rem;
    line-height: 2rem;
}

.detailInner {
    width: 100%;
    margin: 20px auto;
    color: #000000;
    margin-top: 40px;

    .title_wrapper {
        width: 100%;
        display: flex;
        font-size: 1.5rem;
        .title_left {
            font-weight: bold;
            &::after {
                content: "|";
                margin: 0 10px;
            }
        }
    }

    .description {
        width: 100%;
        padding: 0;
        margin: 20px 0;
        line-height: 1.6rem;
    }

    .imageWrapper {
        width: 100%;
        margin: 30px auto;
        margin-bottom: 60px;

        // span {
        //     height: 100%;
        //     display: block;
        // }
        img {
            width: 100%;
            // height: 100%;
        }
    }
}

@media (min-width: 1407px) {
    .mainVideo1 {
    }
}

@media (max-width: 1350px) {
    .mainVideo1 {
    }
}

@media (max-width: 1300px) {
    .mainVideo1 {
    }
    
}


@media (max-width: 1280px) {
    .mainImage {
        width: 100%;
        height: initial;
    }
    .popup_contetns{
        min-width: 100% !important;
        
    }
    .scroll_inner{
        flex-direction: column;
        padding : 0px 5px;
        
    }
    .popup_wrapper_all {
        width: 29% !important;
        min-width: 351.47px !important;
        height: 80% !important;
    }
    .overViewGenuin{
        margin : initial;
        width : 100%;
    }
    .left-title{
        flex : 1 !important;
    }
    .firstline1{
        margin-top : 15px;
        margin-bottom : 20px !important;
    }
    .name{
        font-family: Noto Sans KR;
        margin-right : 14px;
        font-size: 26px;
        font-weight: 300;
    }
    .experience{
        font-family: Noto Sans KR;
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 20px !important;
    }
    .extext{
        font-family: Noto Sans KR;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.83;
        width: initial !important;
        height: auto;
        >span{
            font-family: Montserrat;
            font-weight: 600;
            line-height: 2.23;
        }
    }
    .overViewWrap{
        margin-bottom : initial !important;
    }
    
}


@media (max-width : 1070px){
    .overViewGenuin {
        .subImgsBox{
            display : initial;
            justify-content: space-between;
            &:nth-child(1) {
                margin-right : 10px;
            }
            
            .subImgsContainer{
                flex : 1;
                margin-right :initial;
                img{
                    width : 100%;
                    max-width: 688px;
                }
            }
            .subImgsContainer1{
                margin-top : 30px;
                flex : 1;
                margin-left :initial;
                img{
                    width : 100%;
                    max-height: 688px;
                }
                
            }
            
            .subdectitle{
                margin-top : 30px;
                margin-bottom : 18px;
                font-size: 25px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.92;
                letter-spacing: normal;
                text-align: left;
                color: #232323;
            }
            .subdec{
                font-size: 15px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.53;
            }

        }
    }
}
@media (max-width : 733px){
    .overViewGenuin {
        .name{
            margin-left : 5px;
            font-family: Montserrat-Bold;
            margin-right : initial;
            font-size: 20px;
        }
        .smallThumbs{
            margin-top : 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            img{
                width : 100%;
            }
            .smallThumbImg{
                width : 48%;
                margin-bottom : 20px;
            }
            
        }
        .behindTheSceneBox{
            text-align: center;
            font-family: Montserrat-bold;
            font-size: 30px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.77;
            letter-spacing: normal;
            color: #151515;
            margin-top : 53px;
            .behindTheSceneImgBox{
                margin-top : 53px;
                margin-bottom: 50px;;
                display: flex;
                justify-content: space-between;
                img{
                    width : 100%;
                }
                .behindTheSceneImg{
                    width : 49.1%;
                }
            }
        }
    }
}
@media (max-width : 733px){
    // .popup_wrapper_all_genuin {
    //     padding : 35px 20px !important;
    //     width: 81% !important;
    //     min-width: initial !important;
    //     height: 80% !important;
    // }
    .overViewGenuin {
        .type{
            margin-bottom : 10px;
        }
        .name{
            margin-left : 5px;
            font-family: Montserrat-Bold;
            margin-right : initial;
            font-size: 17px;
        }
        .footer1{
            .footerlogo{
                margin-top : 27px;
                margin-bottom: 22px;;
            }
        }
        .subdectitle{
            font-size : 17px !important;
        }
        .smallThumbs{
            margin-top : 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            img{
                width : 100%;
            }
            .smallThumbImg{
                width : 48%;
                margin-bottom : 20px;
            }
            
        }
        .behindTheSceneBox{
            text-align: center;
            font-family: Montserrat-bold;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.77;
            letter-spacing: normal;
            color: #151515;
            margin-top : 53px;
            .behindTheSceneImgBox{
                margin-top : 53px;
                margin-bottom: 50px;;
                display: flex;
                justify-content: space-between;
                img{
                    width : 100%;
                }
                .behindTheSceneImg{
                    width : 49.1%;
                }
            }
        }
    }
}
@media (max-width: 414px) {
    .footer1{
        background-color:  #151515;
        display: flex;
        justify-content: center;
        .footerlogo{
            width : 45%;
            margin-top : 83px;
            margin-bottom: 78px;;
        }
    }
}
@media (max-width: 379px) {
    .footer1{
        background-color:  #151515;
        display: flex;
        justify-content: center;
        .footerlogo{
            width : 45%;
            margin-top : 83px;
            margin-bottom: 78px;;
        }
    }
}
@media (max-width: 379px) {
    .mainImage {
        width: 100%;
        height: initial;
    }
    .popup_contetns{
        min-width: 100% !important;
        
    }
    .scroll_inner{
        flex-direction: column;
    }
    // .popup_wrapper_all_genuin {
    //     padding : 35px 20px !important;
    //     width: 91% !important;
    //     min-width: initial !important;
    //     height: 80% !important;
    // }
    .overViewGenuin{
        margin : initial;
        width : 100%;
        min-width : initial !important;
        max-width : initial!important;
        .footer1{
            .footerlogo{
                margin-top : 27px;
                margin-bottom: 22px;;
            }
        }
    }
    .left-title{
        flex : 1 !important;
        width : 100% !important;
    }
    .firstline1{
        flex-direction: column;
        margin-top : 15px;
        align-items: initial !important;
        
        margin-bottom : 20px !important;
    }
    .name{
        font-family: Noto Sans KR;
        margin-right : 14px;
        font-size: 15px;
        font-weight: 300;
    }
    .experience{
        font-family: Noto Sans KR;
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 20px !important;
    }
    .extext{
        font-family: Noto Sans KR;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.83;
        width: 251px;
        height: auto;
        margin-bottom :20px ;
        >span{
            font-family: Montserrat;
            font-weight: 600;
            line-height: 2.23;
        }
    }
    .overViewWrap{
        margin-bottom : initial !important;
    }
    
}



