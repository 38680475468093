@import '../../../utils.scss';

.solutionUsepage_ {
    width: 100%;
    height: 1600px;
    text-align: center;
    color:black;
    background-color: #F6F6F6;
    position: relative;
    padding: 20px 0px;
    box-sizing: border-box;

    .imgad {
        width: 70%;
        margin: 0 auto;
    }
    .scroll {
        width: 100%;
        height: auto;
        position: absolute;
    }

    .righttext {
        float:right;
    }

    .md_text {
        font-size: 80px;
        margin: -40px;
        text-align: center;
    }

    .portfolio {
        width: 0px;
        height: 50px;
        font-size: 30px;
        margin-top: 20px;
        margin-left: 3px;
        //background: $mainColor;
        color: $mainColor;
        background: $mainColor;
        margin-bottom: 100px;
        cursor: pointer;
        -webkit-transition: 0.5s !important;
        -o-transition: 0.5s !important;
        transition: 0.5s !important;
        
        &::before {
            position: absolute;
            width: 12px;
            height: 50px;
            content: '>';
            left: 270px;
            -webkit-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
        }

        &:hover{
            width: 312px;
            color: #ffffff;
            padding-left: 20px;

            &::before {
                left: 290px;
            }

        }
        p{
            font-family: "Montserrat-Regular";
        }
    }
    
    .bold_text_feature {
        font-size: 40px;
        font-weight: bolder;
        margin: 30px;
        .span_wrap {
            height: 60px;
            position: relative;
            box-sizing: border-box;
            &::before {
                content: "";
                border-top: 5px solid;
                width: 50px;
                position: absolute;
                top: 10;
                left: 0;
                box-sizing: border-box;
                padding: 100px 0px;
            }

        }
    }
    
    .position {
        width: 100%;
        height: auto;
    }

    .inner_hover, .inner {
        width: 100%;
        align-items: center;
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
    }

    .inner_color {
        width: 100%;
        align-items: center;
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
    }

    .inner_hover {
        transition: all 0.2s;
        opacity: 0;
    }

    .text_left {
        text-align: left;
        width: 80%;
        margin: 0 auto;
    }
    .inner_title {
        width: 100%;
        color: white;
        font-size: 25px;
        font-weight: bolder;
        position: relative;
    }
    .flex_inner {
        width: 100%;
        position: relative;
        z-index: 5;
    }
    
    .inner_sub_title {
        width: 100%;
        color: white;
        font-size: 18px;
    }

    .more_btn {
        top: 30px;
        justify-content: center;
        color: white;
        display: flex;
        display: flexbox;
        align-items: center;
        border-radius: 20px;
        border: 3px solid white;
        width: 70%;
        height: 35px;
        font-size: 15px;
        margin: 0 auto;
        position: relative;
    }
    .desctext {
        color: white;
        font-size: 15px;
    }

    .add_icon{
        position: absolute;
        right: 5px;
        display: flex;
        display: flexbox;
        align-items: center;
    }

    .imgbox {
        width: 33.3%;
        height: 100%;
        
        background-size: cover;
        float: left;
        position: relative;

        &::before{
            content: "";
            display: block;
            padding-top: 100%;
        }

        .imgintext{
            position: absolute;
        }

        &:hover{
            .inner_hover{
                opacity: 1;
            }
        }
    }
    .fullbox{
        width: 100%;
        height: calc( 100% - 150px );
        overflow: auto;
        @include scroll-style; 
        position: relative;
    }
     

    .time_text {
        font-size: 26px;
        font-weight: 100;
        line-height: 90px;

        span{
            font-size: 16px;
            font-family: "Montserrat-Medium";
            
        }

        .time_num {
            font-size: 150px;
            font-weight: 100;
            font-family: Montserrat-Thin;
        }

    }

}