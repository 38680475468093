@import '../../../utils.scss';


.scroll_wrapper {
    width: 100%;
    overflow-y: auto;
    height: 100%;
    position: relative;
}

.scroll {
    width: 100%;
    height: auto;
    position: absolute;
}

.solutionInfo_ {
    width: 100%;
    height: 1000px;
    text-align: center;
    color: black;
    
    .md_text {
        font-size: 80px;
    }
    .top_in {
        width: 100%;
    }
    .bottom_in {
        width: 100%;
    }

    .small_text {
        margin-top: 30px;
        font-size: 22px;
        line-height: 30px;

        .margin_top_text {
            display: block;
            margin-top: 20px;
        }
    }
    .top {
        height: 50%;
        width: 100%;
        display: flex;
        display: flexbox;
        align-items: center;
        background-color: #F6F6F6;
    }
    .bottom {
        display: flex;
        display: flexbox;
        align-items: center;
        height: 50%;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .bottom_list {
        font-size:20px;
        font-weight: bolder;
        
        li{
            &::before{
                content: "-";
            }
        }
    }

    .bold_text {
        font-family: "Montserrat-Bold";
    }

    .bold_text_info {
        font-size: 40px;
        text-align: center;
        font-weight: bolder;
    }

    .middle_text {
        font-size: 20px;
    }
    .bottom_text {
        color: black;
        font-size: 20px;
        padding: 30px;
    }
    .small_text {
        font-size: 20px;
    }

    .weight600 {
        font-weight: 600;
    }

    .bold_text_feature {
        font-size: 60px;
        font-weight: bolder;

        .span_wrap {
            position: relative;
            box-sizing: border-box;
            &::before {
                content: "";
                border-top: 5px solid;
                width: 80px;
                position: absolute;
                top: 0;
                left: 0;
                box-sizing: border-box;
                padding: 100px 0px;
            }

        }
        
    }
    .middle_text_feature {
        margin: 30px;
        font-size: 20px;
        font-weight: bolder;
        text-align: center;
    }

    .bottom_text_feature {
        color: black;
        font-size: 30px;
        font-weight: bolder;
        text-align: center;
        margin: -10px;
    }
    .imgtop {
        width: 100%;
        height: 100px;
        text-align: center;
    }

    .time_text {
        font-size: 26px;
        font-weight: 100;
        line-height: 90px;

        span{
            font-size: 16px;
            font-family: "Montserrat-Medium";
            
        }

        .time_num {
            font-size: 150px;
            font-weight: 100;
            font-family: Montserrat-Thin;
        }

    }

}