

.wrapper_page{
    color:unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
.KimImgContainer{
    
    margin: 3%;
    text-align: center;
    .Name{
        font-family: 'Montserrat-Medium,Noto Sans KR';
        font-size: 26px;
        font-weight: 300;
    }
    .Job{
        font-family: 'Montserrat-Medium,Noto Sans KR';
        margin-top: 14px;
        font-size: 14px;
        font-weight: 600;
    }
    .Text{
        margin-bottom: 43px;
        font-family: 'Montserrat-Medium,Noto Sans KR';
        margin-top: 52px;
        font-size: 20px;
        font-weight: 500;
    }
    .Portfolio{
        
            width : 100%;
        
    }
    
    }

}

@media screen and (max-width: 620px) {
    .wrapper_page{
    .KimImgContainer{
        .Name{
        }
        .Job{
        }
        .Text{
            margin-top: 30px;
            margin-bottom : 30px;
            font-size: 15px;
        }
        .Portfolio{
            
        }
        
        }
    
    }
}


@media screen and (max-width: 360px) {
    .wrapper_page{
    .KimImgContainer{
        .Name{
            font-size: 20px;
        }
        .Job{
            font-size: 12px;
        }
        .Text{
            margin-top: 20px;
            margin-bottom : 20px;
            font-size: 11px;
        }
        .Portfolio{
            
        }
        
        }
    
    }
}

