@import '../../utils.scss';

.menu_nav {
    position: absolute;
    top: 50%;
    z-index: 5;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 20px;
    text-align: right;

    a {
        position: relative;
        right: 0;
        display: block;
        width: 7px;
        height: 7px;
        margin: 7px 0;
        background-color: white;
        -webkit-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
        
        &::before {
            content: "";
            width: 7px;
            height: 7px;
            position: absolute;
            background-color: white;
            right: 0;
            -webkit-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
            z-index: 0px;
        }

        &:hover {
            &::before {
                background-color:#199dfb;
                width: 30px;
            }
        }
        
    }

    .active {
        background-color: #199dfb;
        &::before {
            background-color: #199dfb;
            width: 25px;
        }
    }

    &.black {
        a {
            &::before {
                background-color: #000000;
            }
            // display: block;
            // width: 10px;
            // height: 10px;
            // margin: 10px 0;
            // transition: all 0.2s;
        }
    
        .active {
            &::before {
                background-color: #199dfb;
            }
        }
    }

}

@media (min-width: 1800px) {
    .menu_nav{
        right: 100px;
        a {
    
            &:hover {
                &::before {
                    width: 30px;
                }
            }
        }
        
    }
}
    

@media (min-width: 1024px) {
    .menu_nav{
        right: 100px;
        a {
            &:hover {
                &::before {
                    width: 30px;
                }
            }
        }
    }
}

@media (max-width: 670px) {

    .menu_nav {
        right: 20px;
        .active {
            &::before {
                width: 10px !important;
            }
        }
    }
    
}