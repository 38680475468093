.mainImage {
  width: 302px;
  height: 407px;
  img {
    position: fixed;
    width: 302px;
    height: auto;
  }
}

.overView {
  width: 40%;
  margin: 0px auto;
  color: #000000;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  .left-title {
    flex: 3;
    display: flex;
    flex-direction: column;
    height: 412px;
    justify-content: space-between;
  }
  .right-title {
    flex: 5;
    display: flex;
    align-items: flex-end;
  }
  .overViewWrap {
    width: 100%;
    // margin-top: 40px;
    margin-bottom: 0px;
  }
  .title {
    white-space: nowrap;
    font-size: 2.5rem;
  }
  .border {
    width: 100%;
    // margin-left: 20px;
    border: none;
    height: 100%;
    border-bottom: 1px solid #000000;
    margin-bottom: 0;
  }
  .type {
    font-size: 18px;
    font-weight: 700;
    font-family: Montserrat;
    margin-bottom: 30px;
  }
  .firstline {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  .company {
    display: flex;
    align-items: center;
    margin-top: 3px;
    font-weight: 600;
    font-size: 12px;
    font-family: Montserrat-SemiBold;
  }
  .name {
    font-family: Noto Sans KR;
    margin-right: 14px;
    font-size: 20px;
    font-weight: 300;
  }
  .experience {
    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 30px;
  }
  .extext {
    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
    width: 100%;
    height: auto;
    // padding-right: 85px;

    > span {
      font-family: Montserrat;
      font-weight: 600;
      line-height: 2.23;
      font-size: 12px;
    }
  }
  .youtube {
    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.83;
    width: 251px;
    height: auto;
    margin-top: 23px;
    > a {
      font-family: Montserrat;
      font-weight: 400;
      margin-right: 10px;
    }
  }
}

.mainVideo {
  width: 100%;
  height: 85vh;

  video {
    width: 100%;
    height: auto;
  }
}

.description {
  width: 100%;
  margin: 40px auto;
  color: #000000;
  font-size: 1.5rem;
  line-height: 2rem;
}

.detailInner {
  width: 100%;
  margin: 20px auto;
  color: #000000;
  margin-top: 40px;

  .title_wrapper {
    width: 100%;
    display: flex;
    font-size: 1.5rem;
    .title_left {
      font-weight: bold;
      &::after {
        content: '|';
        margin: 0 10px;
      }
    }
  }

  .description {
    width: 100%;
    padding: 0;
    margin: 20px 0;
    line-height: 1.6rem;
  }

  .imageWrapper {
    width: 100%;
    margin: 30px auto;
    margin-bottom: 60px;

    // span {
    //     height: 100%;
    //     display: block;
    // }
    img {
      width: 100%;
      // height: 100%;
    }
  }
}

@media (min-width: 1407px) {
  .mainVideo {
    height: 75vh;
  }
}

@media (max-width: 1350px) {
  .mainVideo {
    height: 75vh;
  }
}

@media (max-width: 1300px) {
  .mainVideo {
    height: 75vh;
  }
}

@media (max-width: 1280px) {
  .mainImage {
    width: 100%;
    height: initial;
    img {
      position: initial;
      width: 100%;
    }
  }
  .popup_contetns {
    min-width: 100% !important;
  }
  .scroll_inner {
    flex-direction: column;
    padding: 5px;
  }
  .popup_wrapper_all {
    width: 29% !important;
    min-width: 351.47px !important;
    height: 80% !important;
  }
  .overView {
    margin: initial;
    width: 100%;
  }
  .left-title {
    flex: 1 !important;
    height: unset !important;
  }
  .firstline {
    margin-top: 15px;
    margin-bottom: 20px !important;
  }
  .name {
    font-family: Noto Sans KR;
    margin-right: 14px;
    font-size: 26px;
    font-weight: 300;
  }
  .experience {
    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 20px !important;
  }
  .extext {
    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.83;
    height: auto;
    > span {
      font-family: Montserrat;
      font-weight: 600;
      line-height: 2.23;
    }
  }
  .overViewWrap {
    margin-bottom: initial !important;
  }
}

@media (max-width: 379px) {
  .mainImage {
    width: 100%;
    height: initial;
  }
  .popup_contetns {
    min-width: 100% !important;
  }
  .scroll_inner {
    flex-direction: column;
  }
  .popup_wrapper_all {
    padding: 35px 20px !important;
    width: 91% !important;
    min-width: initial !important;
    height: 80% !important;
  }
  .overView {
    margin: initial;
    width: 100%;
    min-width: initial !important;
    max-width: initial !important;
  }
  .left-title {
    flex: 1 !important;
    width: 100% !important;
    height: unset !important;
  }
  .firstline {
    flex-direction: row !important;
    margin-top: 15px;
    align-items: initial !important;

    margin-bottom: 20px !important;
  }
  .name {
    font-family: Noto Sans KR;
    margin-right: 14px;
    font-size: 26px;
    font-weight: 300;
  }
  .experience {
    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 20px !important;
  }
  .extext {
    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.83;
    width: 251px;
    height: auto;
    margin-bottom: 20px;
    padding-right: 0px !important;
    > span {
      font-family: Montserrat;
      font-weight: 600;
      line-height: 2.23;
    }
  }
  .overViewWrap {
    margin-bottom: initial !important;
  }
}
