@import '../../../utils.scss';

// *:lang(en) {
//     font-family: Montserrat-Regular;
// }

.teams {
    width: 100%;
    height: auto;
    color : #ffffff;
    .title_big {
        font-size: 90px;
        font-family: 'Montserrat-bold,Noto Sans KR';
        text-align: center;
        margin-bottom: 20px;
        transition: all 0.2s;
        h1{
            font-size: inherit;
            font-family: inherit;
            text-align: inherit;
            margin-bottom: inherit;
            transition: inherit;
        }
    }

    .list_wr {
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        height: 60vh;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        @include scroll-style;
        box-sizing: border-box;

        .wr {
            display : flex;
            flex-wrap : wrap;
            width: 100%;
            height: 100%;
            margin: 0 auto;
        }

        .list_all {
            // min-width: 270px;
            height: auto;
            float: left;
            transition: all 0.2s;
            

            &:nth-last-child(1) {
                margin-bottom: 0;
            }

            .list_name {
                font-size: 19.5px;
                font-family: 'Montserrat-Light,Noto Sans KR';
                margin-top : 28px;
                margin-bottom: 21px;
                // padding-left: 2.5%;
                > span{
                    font-family: 'Montserrat-Light,Noto Sans KR';
                    margin-left : 14px;
                    font-size: 13px;
                    font-weight: 300;
                }
            }
            
            .list_ {
                width: 100%;
                height: auto;
                >img {
                    width: 100%;
                }

                .list_scroll {
                    width: auto;

                    li {
                        // height: 35px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                            -ms-flex-align: center;
                                align-items: center;
                        white-space: nowrap;
                        width: 100%;
                        position: relative;

                        .hoverEmail {
                            position: absolute;
                            left: 0;
                            top: 0;
                            // height: 35px;
                            background-color: $mainColor;
                            width: 0;
                            // opacity: 0;
                            -webkit-transition: width 0.5s;
                            -o-transition: width 0.5s;
                            transition: width 0.5s;
                            z-index: 5;
                            opacity: 0;
                            padding-left: 10px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            transition: all 0.2s;

                            &.clip {
                                background-color: white;
                                color: $mainColor;
                                font-weight: bold;
                            }
                        }

                        p {
                            float: left;
                            font-size: 12px;
                            width: 50%;
                            position: relative;
                            z-index: 5;
                            display: flex;
                            align-items: center;
                            // height: 35px;
                            margin: 0;
                        }   

                        span {
                            opacity: 0;
                            -webkit-transition: all 0.5s;
                            -o-transition: all 0.5s;
                            transition: all 0.5s;
                            position: absolute;
                            z-index: 5;
                            right: 4.5%;
                        }
                        
                        .name {
                            width: 100%;
                            font-family: 'Montserrat-Light,Noto Sans KR';
                            white-space: pre-line;
                            font-size: 12px;
                            font-weight: 100;
                            -webkit-box-sizing: border-box;
                                    box-sizing: border-box;
                            // padding-left: 3%;
                            display: flex;
                            align-items: center;
                            line-height: 1.5;
                            margin-bottom : 21px;
                            // height: 35px;
                            margin: 0;
                            opacity: 1;
                            transition: all 0.1s;
                        }

                        
                        &:hover {
                            .hoverEmail {
                                width: 110%;
                                opacity: 1;
                            }
                            span {
                                opacity: 1;
                            }
                            // .name {
                            //     opacity: 0;
                            // }
                            // cursor: pointer;
                        }
                    }

                }

            }

        }

    }

}
@media (min-width: 1611px) {
    .list_wr {
        width: 100%;

        .list_all {
            width: 22%;
            margin: 1.5%;
        }
    }
}

@media  (max-width: 1610px) {
    .list_wr {
        .list_all {
            width: 30%;
            margin: 1.6%;
        }
    }
}

@media (max-width: 1170px) {
    .list_wr {
        .list_all {
            width: 45% ;
            margin: 2.5%;
        }
    }
}

// @media (max-width: 780px) {
//     .list_wr {
//         width: 90%;
//         margin-left: auto;
//         margin-right: auto;
//         padding: 0 30px;
        
//         .list_all {
//             width: 45%;
//             margin: 2.5%;
//         }
//     }
// }

@media (max-width: 780px) {
    .title_big {
        font-size: 50px !important;
        h1{
            font-size: inherit;
            font-family: inherit;
            text-align: inherit;
            margin-bottom: inherit;
            transition: inherit;
        }
    }
    .list_wr {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 0 40px;
        box-sizing: border-box;
        
        .list_all {
            width: 100%;
            margin-bottom: 50px;

            // .list_ {
            //     .list_scroll {
            //         .name {
            //             font-size: 10px;
            //         }       
            //     }
            // }
        }
    }

}

@media (max-width: 400px) {
    .title_big {
        font-size: 40px !important;
        h1{
            font-size: inherit;
            font-family: inherit;
            text-align: inherit;
            margin-bottom: inherit;
            transition: inherit;
        }
    }
    .list_wr {
        margin-left: 10px;
        margin-right: 52.825;
        padding-right: 10px;
        padding-left: 0px;
        box-sizing: border-box;
        
        .list_all {
            width: 100%;
            margin-bottom: 50px;
            
            // .list_ {
            //     .list_scroll {
            //         .name {
            //             font-size: 8px;
            //         }       
            //     }
            // }
        }
    }
}

@media (max-width: 320px) {

    .teams{
        
    .title_big {
        font-size: 40px !important;
        h1{
            font-size: inherit;
            font-family: inherit;
            text-align: inherit;
            margin-bottom: inherit;
            transition: inherit;
        }
    }
    .list_wr {
        margin-left: 10px;
        margin-right: 52.825;
        padding-right: 10px;
        padding-left: 0px;
        box-sizing: border-box;
        
        .list_all {
            width: 100%;
            margin-bottom: 50px;
            
            .list_ {
                .list_scroll {
                    .name {
                        font-size: 6px;
                    }       
                }
            }
        }
    }
}

}