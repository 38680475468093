@import '../../../utils.scss';



.works_ {


    width: 100%;
    height: 100%;
    padding: 43px 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;

    .logo_title {
        width: 100%;
        height: 40px;

        color: black;
        
        .logo {
            width: 40px;
            height: 40px;
            float: left;
            img {
                width: 100%;
                height: 100%;
            }
        }

        .title {
            padding-top: 5px;
            float: left;

            p {
                font-family: 'Montserrat-Light,Noto Sans KR';
                font-size: 14px;
            }

            .bold {
                font-size: 16px;
                font-family: 'Montserrat-Bold';
            }
            // margin-left: 10px;
        }

    }
  

    .menu_tab {
        width: 100%;
        color: #b4b4b4;
        font-size: 11px;
        margin-top: 20px;
        margin-bottom: 10px;
        // height: 35px;
        // overflow-y: hidden;

        .selectBox { 
            width: 100%;
            height: auto;
            display: none;
        }
       
    
        ul {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;

            li {
                padding: 2px 5px;
                -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                cursor: pointer;
                font-family: Montserrat-Medium;
                -webkit-transition: all 0.2s;
                -o-transition: all 0.2s;
                transition: all 0.2s;

                &:hover {
                    color: rgba(0, 0, 0, 0.5);
                }
            }

            .select {
                color: black;
                &:hover {
                    color: black;
                }
            }

        }

    }

    .card_all_wrapper {
        width: 100%;
        height: calc(100% - 100px);

        .card_wr {
            width: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            position: relative;
            animation-duration: 0.2s !important;
            @include scroll-style;

            &.ALL, &.INTERACTIVE, &.DIGITAL, &.EXHIBITION, &.FILM, &.LAB {
                .card {
                    // width: 313px;
                    // height: 407px;
                    -webkit-animation-name: show;
                            animation-name: show;
                    -webkit-animation-duration: 0s !important;
                            animation-duration: 0s !important;
                            > * {
                                -webkit-transition-delay: 0s !important;
                                -o-transition-delay: 0s !important;
                                transition-delay: 0s !important;
                                animation-duration: 0s !important;
                            }
                }

                @-webkit-keyframes show {
                    from {
                        opacity: 0;
                        -webkit-transform: translateY(-25px);
                                transform: translateY(-25px);
                    }

                    to {
                        opacity: 1;
                        -webkit-transform: translate(0, 0);
                                transform: translate(0, 0);
                    }
                }

                @keyframes show {
                    from {
                        opacity: 0;
                        -webkit-transform: translateY(-25px);
                                transform: translateY(-25px);
                    }

                    to {
                        opacity: 1;
                        -webkit-transform: translate(0, 0);
                                transform: translate(0, 0);
                    }
                }
            }

            .scroll {
                width: 99%;
                height: auto;
                position: absolute;
                // left: -1.3%;
            }

            .card_pd {
                width: 30%; 
                position: relative;
                margin: 1%;
                float: left;
                

                &::before {
                    content: "";
                    display: block;
                    padding-top: 134%;
                }
            }

            .inner_flex {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .card {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                .contents_hide {
                    position: absolute;
                    z-index: 5;
                    -webkit-transition: all 0.2s !important;
                    -o-transition: all 0.2s !important;
                    transition: all 0.2s !important;
                    // transition-duration: 0.1s !important;
                    transition-delay: 0s !important;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    position: absolute;
                    background-color: rgba($color: #f05229, $alpha: 0);
                    color: transparent;
                    // // color: white;
                    // background-color: #0000007c; 
                    -webkit-box-sizing: border-box;
                            box-sizing: border-box;
                    padding: 20px 20px;
                    display: flex;
                    align-items: center;

                    > * {
                        -webkit-transition: all 0s !important;
                        -o-transition: all 0s !important;
                        transition: all 0s;
                        transition-duration: 0s !important;
                        transition: all 0s !important;
                        transition-delay: 0s !important;
                    }
                }

                .contents_img {
                    width: 100%;
                    height: 100%;
                    background-color: black;
                    background-size: cover;
                    background-position: center;
                }

                &:hover {
                    -webkit-transform: translateY(-5px);
                        -ms-transform: translateY(-5px);
                            transform: translateY(-5px);
                    .contents_hide {
                        height: 100%;
                        // padding: 20px;
                        background-color: rgba($color: #000000, $alpha: 0.5);
                        color: white;
                        .title {
                            white-space: normal;
                            text-overflow: inherit;
                            overflow: inherit;
                        }
            
                    }

                    .desc {
                        font-family: 'Montserrat-SemiBold,Noto Sans KR';
                        font-size: 11px;
                        text-align: left;
                    }
    
                    // .partners {
                    //     margin-top: 8px;
                    //     font-family: Montserrat-SemiBold;
                    //     font-size: 18px;
                    //     font-weight: bold;
                    // }
    
                    // .title {
                    //     font-family: Montserrat-Light;
                    //     font-size: 18px;
                    //     // margin-top: -10px;
                    // }
                    cursor: pointer;
                }

                &:active {
                    -webkit-transform: translateY(0px);
                        -ms-transform: translateY(0px);
                            transform: translateY(0px);
                }

                .desc {
                    font-family: 'Montserrat-SemiBold,Noto Sans KR';
                    font-size: 11px;
                    text-align: left;
                }

                .partners {
                    margin-top: 10px;
                    font-family: 'Montserrat-SemiBold,Noto Sans KR';
                    font-size: 18px;
                    font-weight: bold;
                }

                .title {
                    font-family: 'Montserrat-light,Noto Sans KR';
                    font-size: 18px;
                    // margin-top: -10px;
                }

            }

        }

    }

  
    .footer {
        width: 100%;
        position: fixed;
        bottom: 0;
        height: 40px;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        color: black;
        display: flex;
    align-items: center;

        p {
            margin: 0 5px;
            color: #b4b4b4;
            cursor: pointer;
            font-family: Montserrat-Medium;
            font-size: 11px;
            // padding-bottom: 5px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
        }

        p:nth-child(1) {
            margin-left: 15px;
            cursor: pointer;
        }
        
        img {
            display: flex;
            // height: 45px;
        }
        
    }

}

.contact_works {
    width: 150px;
    height: 50px;
    background-color: $mainColor;
    margin: 30px auto;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }

    &:active {
        transform: translateY(5px);
    }
    // fos
}

.email_popup {

    .connect_with_us {
        color: white;
    }
    
    &.works_popup {
        position: fixed; 
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: 0px 0px 500px gray;
    }
}


@media (max-height: 600px) {
    .email_popup_inner_scroll {
        height: 60vh !important;
    }
}

@media (max-width: 1024px) {
    .email_popup {
        // position: fixed;
        position: relative;
        bottom: 0;
        z-index: 30;
        left: 0;
        width: 90vw;
        left: -15%;
        height: 100vh;
        top: 0;

        .orange_area {
            // padding-top: 20px;

            .connect_with_us {
                font-size: 25px;
            }
        }

        .X_btn {
            left: -5PX;
            top: 0;
            background-color: transparent;
            span {
                font-size: 20px !important;
                color: white;
            }

            &.out {
                display: none;
            }

            &.inner {
                display: flex;
            }
        }

        .email_popup_inner {
            height: 100%;
        }

        .email_popup_inner_scroll {
            height: 100% !important;

            .warning_text {
                font-size: 13px;
            }
        }
    }
}

@media (max-width: 400px) {
    .warning_text {
        font-size: 11px !important;
    }

    .popup_contents_input {
        input {
            font-weight: bold;
        }
        .Dropdown-placeholder {
            font-size: 13px !important;
            font-weight: bold;
            white-space: nowrap;
        }
    }
}

@media (max-height: 660px) {
    .popup_contents_inquiry {
        height: 42% !important;
    }

    .info_use {
        margin-top: -5%;
    }

    .warning_text {
        bottom: 29% !important;
    }
}


@media (max-height: 510px) {
    .email_popup {
        height: 510px !important;
    }
}


@media (min-width: 1750px) {
    .card_pd {
        width: 24% !important;
        margin: .5% !important;
    }

    .contents_hide {
        padding: 20px !important;
    }
}

@media (max-width: 1140px) {
   .works_{
       .menu_tab{
            
       .selectBox {
            display: block;
        }
       }
   }
    .card_pd {
        width: 45% !important;
        margin: 2.5% !important;
    }
}

@media (max-width: 720px) {
    .works_ {
        padding: 43px 20px 
    }
    .menu_tab {
        // height: 200px;
        margin-top: 20px !important;

        .selectBox { 
            visibility: visible;
        }
        ul {
            display: block !important;
            li {
                text-align: left;
            }
        }
    }


    .card_pd {
        width: 90% !important;
        margin: 5% !important;

        .contents_hide {
            color: white !important;
            background-color: #0000006b !important;
            height: 110px !important;
            bottom: 0;
            .desc {
                font-size: 14px !important;
            }

            .partners {
                margin-top: 5px !important;
            }
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        display: none;
        opacity: 0; 
    }
}


.MuiDialog-paperWidthSm {
    max-width: none !important;
}




// @media (min-height: 1051px) {
//     .works_ {
//         padding: 20px 
//     }
//     .card_all_wrapper {
//         height: calc(100%- 40px) !important;
//     }
// }

// @media (max-height: 1050px) {
//     .works_ {
//         padding: 20px 
//     }
//     .card_all_wrapper {
//         height: 93% !important;
//     }
// }

// @media (max-height: 890px) {
//     .works_ {
//         padding: 20px 
//     }
//     .card_all_wrapper {
//         height: 85% !important;
//     }
// }

// @media (max-height: 820px) {
//     .works_ {
//         padding: 20px 
//     }
//     .card_all_wrapper {
//         height: 82% !important;
//     }
// }

// @media (max-height: 720px) {
//     .works_ {
//         padding: 20px 
//     }
//     .card_all_wrapper {
//         height: 75% !important;
//     }
// }

// @media (max-height: 600px) {
//     .works_ {
//         padding: 20px 
//     }
//     .card_all_wrapper {
//         height: 70% !important;
//     }
// }

// @media (max-height: 500px) {
//     .works_ {
//         padding: 20px 
//     }
//     .card_all_wrapper {
//         height: 60% !important;
//     }
// }

// @media (max-width: 717px) {
//     .works_{
//         .menu_tab{
//             margin-bottom: -150px;
//         }    
//     }
// }


